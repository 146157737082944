import * as Ajax from './ajax'
const STEKI_METER_HEIGHTS = ['0%', '25%', '33%', '40%', '48%', '56%', '65%', '74%', '83%', '92%', '100%']

/**
 * 実行可能状態
 */
$(document).ready(function () {
  /**
   * kartes_controller
   */
  if (document.body.dataset.controller == 'kartes') {
    /**
     * show action
     */
    if (document.body.dataset.action == 'show') {
      // 選択中のカルテ画像をタップ
      $('.karte-main-photo img').click(function () {
        $('.download-image-confirm-dialog').show()
      })

      // カルテ一覧の写真をタップ
      $('.karte-main-photos .exists a').click(function (e) {
        // タップしたサムネイルをアクティブに
        $('.karte-main-photos li').removeClass('active')
        var $photoLi = $(this).parent('li')
        $photoLi.addClass('active')

        // メイン画像を切替
        var imageUrl = $(this).find('img').attr('src')
        $('.karte-main-photo img').attr('src', imageUrl)

        // 画像ダウンロードボタンの画像リンクを変更
        $('.download-image-confirm-dialog .yes').attr('href', imageUrl)

        // ステキボタンのdataを書き換え
        var likeCount = ($photoLi.attr('data-like-count').length === 0) ? '0' : $photoLi.attr('data-like-count')
        var $loveButton = $('.photo-love-button')
        $loveButton.attr('data-suidbase', $photoLi.attr('data-suidbase'))
        $loveButton.attr('data-photoid', $photoLi.attr('data-photoid'))
        $loveButton.attr('data-like-count', likeCount)
        var $maskColor = $loveButton.find('.mask-color')
        if (Number(likeCount) < 10) {
          $maskColor.removeClass('max')
          $loveButton.removeClass('suteki-scale')
        } else {
          $maskColor.addClass('max')
          $loveButton.addClass('suteki-scale')
        }
        $maskColor.removeAttr('style')
        $maskColor.css('height', STEKI_METER_HEIGHTS[likeCount])
      })

      // 画像ダウンロードダイアログ-[はい]をタップ
      $('.download-image-confirm-dialog .yes').click(function () {
        $('.download-image-confirm-dialog').hide()
      })

      // 画像ダウンロードダイアログ-[キャンセル]をタップ
      $('.download-image-confirm-dialog .cancel').click(function () {
        $('.download-image-confirm-dialog').hide()
      })

      // プライベートカルテ写真編集時
      $('.private-karte-photos').on('click', '.modify-private-karte-photo', function (e) {
        $('.private-karte-photo-edit-dialog').show()
      })

      // プライベートカルテ写真を切り替える
      $('.private-karte-photos').on('click', '.private-karte-main-photos a', function (e) {
        $('.private-karte-main-photos li').removeClass('active')
        var $photoLi = $(this).parent('li')
        $photoLi.addClass('active')
        var imageUrl = $(this).find('img').attr('src')
        // メイン画像を切替
        $('.private-karte-main-photo img').attr('src', imageUrl)
        $('.edit-private-photo').attr('data-photoid', $photoLi.attr('data-photoid'))
        $('.delete-private-photo').attr('data-photoid', $photoLi.attr('data-photoid'))
      })

      // プライベートカルテ写真削除
      $('.delete-private-photo').click(function (e) {
        var kSuidbase = $('.private-area').attr('data-ksuidbase')
        var kSuid = $('.private-area').attr('data-ksuid')
        var photoid = $(this).attr('data-photoid')
        if (!confirm('本当に削除しますか？')) {
          // キャンセルの時の処理
          return false
        } else {
          // 画像削除
          Ajax.deletePrivateKartePhoto(kSuid, kSuidbase, photoid, (result) => {
            // nothing to do
          })
        }
      })

      // プライベートカルテメモ編集ボタン押下
      $('.private-area').on('click', '.edit-private-karte-memo', function (e) {
        $('.private-karte-memo-dialog').show()
      });
    }

    // ----------------------------------------
    // ステキボタン
    // ----------------------------------------

    // カルテ写真ステキボタン押下
    $('.photo-love-button.clickable').click(function () {
      var kSuid = $(this).attr('data-ksuid')
      var kSuidbase = $(this).attr('data-ksuidbase')
      var suidbase = $(this).attr('data-suidbase')
      var photoid = $(this).attr('data-photoid')
      var likeCount = $(this).attr('data-like-count')
      if (likeCount >= 10) {
        return
      }
      // ステキボタンと写真リスト側のdata-like-countをカウントアップ
      var uppedLikeCount = ++likeCount
      $(this).attr('data-like-count', uppedLikeCount)
      $('.karte-main-photos li.active').attr('data-like-count', uppedLikeCount)
      setStekiWithAnimate($(this), uppedLikeCount)
      if (uppedLikeCount >= 10) {
        showCheerDialog()
      }
      Ajax.setKartePhotoLike(kSuid, kSuidbase, suidbase, photoid, uppedLikeCount, (result) => {
        // nothing to do
      })
    })

    // カルテ共有コメントのステキボタン押下
    $('.comment-love-button.clickable').click(function () {
      var kSuid = $(this).attr('data-ksuid')
      var kSuidbase = $(this).attr('data-ksuidbase')
      var suidbase = $(this).attr('data-suidbase')
      var likeCount = $(this).attr('data-like-count')
      if (likeCount >= 10) {
        return
      }
      // ステキボタンと写真リスト側のdata-like-countをカウントアップ
      var uppedLikeCount = ++likeCount
      $(this).attr('data-like-count', uppedLikeCount)
      setStekiWithAnimate($(this), uppedLikeCount)
      if (uppedLikeCount >= 10) {
        showCheerDialog()
      }
      Ajax.setKarteCommentLike(kSuid, kSuidbase, suidbase, uppedLikeCount, (result) => {
        // nothing to do
      })
    })

    

    $('.no-cheer').click(function () {
      $('.cheer-for-stylist-dialog-wrap').fadeOut()
      $('.cheer-for-stylist-dialog').fadeOut()
    })

    /**
     * index action
     */
    if (document.body.dataset.action == 'index') {
      // 画像のアップロード
      $('.image-photo').on('click', function() {
        $('.file-photo').click()
      })
      $('.file-photo').on('change', function() {
        const file = $('.file-photo')[0].files[0]
        const reader = new FileReader();
        reader.onload = function() {
          Ajax.uploadUserPhoto(reader.result, function(s3Path) {
            $('.image-photo').attr('src', s3Path)
          })
        }
        reader.readAsDataURL(file)
      })

      //STEKINA_PATH
      // クリックした予約の情報をダイアログに反映(フルビューにする)
      const stekina_profile_path = '/user/kartes'
      // 確認ダイアログを開く
      $('.link-confirm').on('click', function () {
        var reserve_id = $(this).data('reserveId')
        console.log(getUrlParameter('channel_id'))
        const url = stekina_profile_path + '/line_channel?channel_id=' + getUrlParameter('channel_id')
        $.ajax({
          url: url,
          type: 'get',
        }).done(function (data) {
          var line_app_id_fullview = data["line_app_id_fullview"]
          if (line_app_id_fullview) {
            //Liff fullview 遷移
            var line_liff_fullview_link = "https://liff.line.me/" + line_app_id_fullview + "?move_to=chat&r_reserve_id=" + reserve_id + "&line_app_id=" + line_app_id_fullview

            $('.link-message-confirm').attr('href', line_liff_fullview_link)
          } else {
            $('.link-message-confirm').attr('href', '/user/reserves/' + reserve_id)
          }
        }).fail((jqXHR, textStatus, errorThrown) => {
          console.log('fail', jqXHR.status);
          $('.link-message-confirm').attr('href', '/user/reserves/' + reserve_id)
        })

        $('.link-message-confirm').attr("data-reserve-id", reserve_id)
      

        if ($(this).data('salon-google-map-url')) {
          $('.link-map-to-salon').attr('href', encodeURI($(this).data('salon-google-map-url')))
        }
        else{ 
          $('.link-map-to-salon').attr('href', encodeURI('https://www.google.com/maps/place/' + $(this).data('address')))
        }
      

        // 開く
        $('.detail-confirm-dialog').fadeIn()
        $('.common-dialog-mykarte-bg').fadeIn()

        $(".cancel-or-change-dialog").hide()
        checkReserveCancelPermition(reserve_id);
      })

      // 確認ダイアログを閉じる
      $('.button-dialog-close,.common-dialog-mykarte-bg').on('click', function () {
        // 閉じる
        $('.detail-confirm-dialog').hide()
        $('.common-dialog-mykarte-bg').hide()
        $('.reserve-cancel-or-change-dialog').hide()
        $('.confirm-cancel-dialog').hide()
        $('.failed-cancel-reserve-dialog').hide()
      })

      // キャンセル・予約変更
      $('.cancel-or-change-dialog').on('click', function () {
        $('.detail-confirm-dialog').hide()

        var permit_cancel = $(this).data("permit-cancel")
        if (permit_cancel == "1") {
          $(".confirm-cancel-reservation-button-area").show()
        } else {
          $(".confirm-cancel-reservation-button-area").hide()
        }
        $('.reserve-cancel-or-change-dialog').show()
      })

      //キャンセル導線開始
      $('.confirm-cancel-reservation-button').on('click', function () {
        $('.reserve-cancel-or-change-dialog').hide()
        $('.confirm-cancel-dialog').show();
      })


      // キャンセル実行ボタン押下
      $('.cancel-reservation-button').on('click', function () {
        
        var reserve_id = $(".cancel-reservation-button").attr("data-cancel-reserve-id")
        var ruid = $(".wrapper-mykarte-list").data('ruid')
        $('.confirm-cancel-dialog').hide();
        cancelReservation(ruid, reserve_id)
      })

      // キャンセル完了ダイアログを閉じる
      $('.canceled-reservation-button').on('click', function () {
        $('.canceled-reserve-dialog').hide()
        $('.reserve-cancel-or-change-dialog').hide()
        location.reload();
      })

      //キャンセル失敗ダイアログでOK押下
      $('.failed-cancel-reservation-button').on('click', function () {
        // 閉じる
        $('.failed-cancel-reserve-dialog').hide()
        $('.detail-confirm-dialog').hide()
        $('.common-dialog-mykarte-bg').hide()
        $('.reserve-cancel-or-change-dialog').hide()
        $('.confirm-cancel-dialog').hide()
        location.reload();
      })

      //予約変更
      $('.change-reservation-button').on('click', function () {
        $('.reserve-cancel-or-change-dialog').hide()
        var r_reserve_id = $(".change-reservation-button").attr("data-change-reserve-id")
        changeReservation(r_reserve_id)
      })
      
      // タブ処理系
      $('.wrap-content.counseling,.wrap-content.photos').hide()
      
      $('.tab').click(function () {
        console.log("tab tap")
        $('.tab').removeClass('active')
        $(this).addClass('active')
        $('.wrap-content').hide()
        $('.wrap-content.' + $(this).data('content')).show()
        

        console.log($(this).data('content'))
        //「写真」に遷移した際に非同期で写真を取得する
        if ($(this).data('content') === "photos") {
          getKartePhotoList();
        }
        //「愛用商品リスト」に遷移した際に非同期取得する
        else if ($(this).data('content') === "purchase-history") {
          getPurchaseHistory();
        }
      });

      // デフォルトタブの指定があれば選択する
      const selectTab = $('.default-tab').data('selectTab')
      if(selectTab) {
        $('.tab[data-content=' + selectTab).click()
      }
    }
  }
})

document.addEventListener("DOMContentLoaded", function() {
  var archiveListItems = document.querySelectorAll(".common-mykarte-archive-list");

  for (var i = 2; i < archiveListItems.length; i++) {
    archiveListItems[i].style.display = "block";
  }
});



function getKartePhotoList() {
  var ruid = $(".wrapper-mykarte-list").data('ruid');
  Ajax.getSharedKartePhotoList(ruid, (result) => {
    let html = "";
    if (result.karte_photos) {
      // 写真一覧をレンダリングする
      let photos = result.karte_photos;

      if (photos.length > 0) {
        html += "<ul id='rizm-photos'>";
        for (var i = 0; i < photos.length; i++) {
          html += "<li>";
          html += "<a href='kartes/" + photos[i].suidbase + "/" + photos[i].k_suid + "'>";
          html += "<div>";
          html += "<img data-src='" + photos[i].s3_image + "'/>";
          html += "</div>";
          html += "</a>";
          html += "</li>";
        }
        html += "</ul>";
      } else {
        html += "<div class='karte-empty-image'>";
        html += "<%= image_tag 'https://limehair.jp/php74-lime/wp-content/uploads/2024/02/defaultscreen-photo-hiragino.png' %>";
        html += "</div>";
      }
    } else {
      html += "<div class='karte-empty-image'>";
      html += "<img src='https://limehair.jp/php74-lime/wp-content/uploads/2024/02/defaultscreen-photo-hiragino.png'/>";
      html += "</div>";
    }
    $(".wrap-content.photos").html(html);

    // 画像の遅延読み込み
    const imageElements = document.querySelectorAll('.wrap-content.photos img[data-src]');
    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;
          const src = image.getAttribute('data-src');
          image.setAttribute('src', src);
          image.removeAttribute('data-src');
          observer.unobserve(image);
        }
      });
    });

    imageElements.forEach((image) => {
      imageObserver.observe(image);
    });
  });
}

// 愛用商品配送ステータステキスト
function createFulfillmentStatusText(fulfillment_status) {
  switch (fulfillment_status) {
    case 0:
      return null
      break;
    case 1:
      return "発送準備中"
      break;
    case 2:
      return "発送済"
      break;
    case 3:
      return "配達完了"
      break;
  
    default:
      return null
      break;
  }
}

// 年末年始かチェック
function isNewYearHolidaySeason() {
  // 現在の時刻を取得
  var currentTime = new Date();

  // 比較用の開始日時と終了日時を作成
  var startDate = new Date("2023-12-27T00:00:00");
  var endDate = new Date("2024-01-04T23:59:59");

  // 現在の時刻が範囲内にあるかどうかを判定
  return currentTime >= startDate && currentTime <= endDate;
}

function getPurchaseHistory() {
  var ruid = $(".wrapper-mykarte-list").data('ruid');
  var liff_full_url = $('input[name=liff-full-url]').val();
  var is_production = $('input[name=is-production]').val();
  var ec_growth_discount_first_percent = $('input[name=ec-growth-discount-first-percent]').val();
  Ajax.getPurchaseHistory(ruid, (result) => {
    console.log('getPurchaseHistory res', result);
    let html = "";
    let purchase_history = result.purchase_history;

    if (purchase_history.length > 0) {
      html += "<ul id='purchase-history-grid'>";
      for (var i = 0; i < purchase_history.length; i++) {
        let history = purchase_history[i];
        const fulfillmentStatusText = createFulfillmentStatusText(history.order_fulfillment_status);
        console.log('fulfillmentStatusText', fulfillmentStatusText);

        html += "<li class='purchase-history-grid-child'>";
        if (history.next_order_date) {
          // html += "<a href='" + liff_full_url + "&move_to=ec_confirm_subscription&referral_suidbase=" + history.suidbase + "&product_variants_id=" + history.ec_product_variants_id + "&subscription_cycle=" + history.cycle + "&from_purchase_history=true'>";
          html += "<a href='" + liff_full_url + "&move_to=subscription-console&ruid=" + ruid + "'>";
        } else {
          html += "<a href='" + liff_full_url + "&move_to=ec&referral_suidbase=" + history.suidbase + "&product_variants_id=" + history.ec_product_variants_id + "&from_purchase_history=true'>";
        }
        html += "<div class='purchase-history-grid-child-overlay'>";
        if (history.next_order_date) {
          html += "<div class='label-box'>";
          html += "<span class='purchased-category'>定期購入中</span>";
          if (fulfillmentStatusText) {
            html += "<span class='fulfillment-status-label'>" + fulfillmentStatusText + "</span>";
          }
          html += "</div>";
          html += "<p class='product-yotei'>次回" + history.next_order_date + "にお届け予定</p>";
        } else {
          if (fulfillmentStatusText) {
            html += "<div class='label-box label-box-higher'>";
            html += "<span class='fulfillment-status-label'>" + fulfillmentStatusText + "</span>";
            html += "</div>";
            html += "<p class='product-yotei'>次回" + history.next_shipping_date_begin + "にお届け予定</p>";
          } else {
            html += "<p class='purchased-date'>" + history.purchase_date +"に購入</p>";
            html += "<p class='product-name'>" + history.variant_name +"</p>";
          }
        }
        html += "</div>";
        html += "<img data-src='" + history.product_image + "'>";
        html += "</a>";
        html += "</li>";
      }
      html += "</ul>";
      html += "<div class='products-search-item' ><a class='products-search-link' href='" + liff_full_url + "&move_to=product-search&ruid=" + ruid + "'>" + "他の商品を検索する></div>";
       
    } else {
      var ranking_products = [];
      var referral_suidbase = null;
      // ランキングに表示する商品リスト

      ranking_products =[{ variant_name: 'ＨＡＩＲ　ＢＡＬＭ　　９９７　　７０ｇ', ec_product_variants_id: '3216', ranking_column_number: '1' },
                          { variant_name: 'The milk for hair care ( ケアミルク)', ec_product_variants_id: '66', ranking_column_number: '2' },
                          { variant_name: 'ＨＡＩＲ　ＯＩＬ　９９７　　１２０ｍｌ', ec_product_variants_id: '3226', ranking_column_number: '3' },
                          { variant_name: 'アルトオイル 50ml（Alto ORGANIC HAIR OIL）', ec_product_variants_id: '3554', ranking_column_number: '4' },
                          { variant_name: 'The oil for styling (スタイリングオイル)', ec_product_variants_id: '64', ranking_column_number: '5' },
                          { variant_name: 'WAVE -HAIR WAX- / ヘアワックス', ec_product_variants_id: '3639', ranking_column_number: '6' },
                          { variant_name: 'ＡＳＩＡ　ＳＰＥＥＤ　ＩＮＰＩＴＡＲＩ　モイストリートメント　７００ｇレフィル', ec_product_variants_id: '2982', ranking_column_number: '7' },
                          { variant_name: 'Ｏ・Ｂａｌｍ　（オーバーム）　ブラック　４５ｍｌ', ec_product_variants_id: '3349', ranking_column_number: '8' },
                          { variant_name: 'ＡＳＩＡ　ＳＰＥＥＤ　ＩＮＰＩＴＡＲＩ　モイストシャンプー　４００ｍｌ', ec_product_variants_id: '2976', ranking_column_number: '9' },
                          { variant_name: '01 golden oil（ゼロワンゴールデンオイル）', ec_product_variants_id: '6987', ranking_column_number: '10' },
                          { variant_name: '新ＴＯＫＩＯ　ＩＥ　インカラミ　プラチナム　シャンプー　７００ｍｌ詰替', ec_product_variants_id: '2888', ranking_column_number: '11' },
                          { variant_name: 'フィヨーレ　クオルシア　カラーシャンプー　パープル　２５０ｍｌ', ec_product_variants_id: '2132', ranking_column_number: '12' },
                          { variant_name: 'カラーモーション＋　ルミナススプレー　２００ｍｌ', ec_product_variants_id: '702', ranking_column_number: '13' },
                          { variant_name: 'ＡＳＩＡ　ＳＰＥＥＤ　ＩＮＰＩＴＡＲＩ　モイストトリートメント　４００ｇ', ec_product_variants_id: '2980', ranking_column_number: '14' },
                          { variant_name: 'ＡＳＩＡ　ＳＰＥＥＤ　ＩＮＰＩＴＡＲＩ　モイストシャンプー７００ｍｌレフィル', ec_product_variants_id: '2978', ranking_column_number: '15' },]
      
      referral_suidbase = 111793;
            
      html += "<div class='title'>まだ購入商品はありません</div>";
      html += "<div class='shokai-otoku-text'>初回限定" + ec_growth_discount_first_percent + "％OFF！</div>";
      html += "<div class='ranking-title'>今人気の商品ランキング</div>";
      html += "<ul id='products-ranking'>";



      // 商品ランキング
      for (var i = 0; i < ranking_products.length; i++) {
        let product = ranking_products[i];

        html += "<li class='products-ranking-item' >";
        html += "<div class='ranking-column-number'>" + product.ranking_column_number + "位　</div>";
        html += "<div class='products-ranking-item-name'>";
        html += "<a class='products-ranking-item-link' href='" + liff_full_url + "&move_to=ec&referral_suidbase=" + referral_suidbase + "&product_variants_id=" + product.ec_product_variants_id + "&from_purchase_history=true'>" + product.variant_name + "</a>";
        html += "</div>";
        html += "</li>";


      }
      html += "</ul>";
      html += "<div class='products-search-item' ><a class='products-search-link' href='" + liff_full_url + "&move_to=product-search&ruid=" + ruid + "'>" + "他の商品を検索する></div>";
    }

    if (isNewYearHolidaySeason() == true) {

    html += "<div class='new-year-schedule'>";
    html += "<p class='nenmatu-yotei'>年末年始は通常とは配送が異なります</p>";
    html += "<p class='nenmatu-yotei'>商品により1/5以降の発送になる場合があります</p>";
    html += "</div>";
      
    }
    
    $(".wrap-content.purchase-history").html(html);

    // 画像の遅延読み込み
    const imageElements = document.querySelectorAll('.wrap-content.purchase-history img[data-src]');
    const imageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const image = entry.target;
          const src = image.getAttribute('data-src');
          image.setAttribute('src', src);
          image.removeAttribute('data-src');
          observer.unobserve(image);
        }
      });
    });

    imageElements.forEach((image) => {
      imageObserver.observe(image);
    });

    // ふわふわをフェードアウト
    $('.unread-badge').fadeOut();
  });
}


//文字のバイト数を取得する関数
function charcount(str) {
  var len = 0;
  var str = escape(str);
  for (var i = 0; i < str.length; i++, len++) {
    if (str.charAt(i) == "%") {
      if (str.charAt(++i) == "u") {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}

function showCheerDialog() {
  var reviewed = $('.already-reviewed').length
  //レビュー済みの場合は表示しない
  if (reviewed == 0) {
    // 美容師さんを応援しませんかダイアログ
    $('.cheer-for-stylist-dialog-wrap').fadeIn()
    $('.cheer-for-stylist-dialog').fadeIn()

    //名前が長い時の文字数対策
    var custmername_text = $(".name-client-charcount").text();
    var custmername_count = charcount(custmername_text);
    if (custmername_count > 18) {
      $(".name-client-charcount").css('font-size', (18 - ((custmername_count - 18) * 0.37)) + 'px');
      $(".name-client-charcount").css('letter-spacing', '-0.5px');  
    }
  }
}

//
// キャンセル・予約変更導線表示制御
//
function checkReserveCancelPermition(r_reserve_id) {
  $("a.cancel-reservation-button").attr("data-cancel-reserve-id", r_reserve_id)
  $("a.change-reservation-button").attr("data-change-reserve-id", r_reserve_id)
  //キャンセルできて良いかAPIアクセス
  Ajax.checkReserveCancelPermition(r_reserve_id, (permit_cancel) => {
    if (permit_cancel) {
      $(".cancel-or-change-dialog").text("予約を変更・またはキャンセル")
      $(".cancel-or-change-dialog").data("permit-cancel", 1)
      $(".cancel-or-change-dialog").fadeIn()
    } else {
      $(".cancel-or-change-dialog").text("予約を変更する")
      $(".cancel-or-change-dialog").data("permit-cancel", 0)
      $(".cancel-or-change-dialog").fadeIn()
    }
  }, () => {
  })
}

//
// 予約キャンセル
//
function cancelReservation(ruid, r_reserve_id) {
  Ajax.cancelReservation(ruid, r_reserve_id, (data) => {
    // //キャンセル完了ダイアログ表示
    $('.common-dialog-mykarte-bg').hide()
    $('.common-dialog-mykarte-bg-untouchable').show()
    $('.canceled-reserve-dialog').show();
  }, () => {
    //キャンセル失敗
    $('.failed-cancel-reserve-dialog').show();
  })
}

//
// 予約変更
//
function changeReservation(r_reserve_id) {
  var channel_id = getUrlParameter('channel_id')
  Ajax.getUpdateReservationUrl(r_reserve_id, channel_id, (update_reserve_url) => {
    if (update_reserve_url) {
      location.href = update_reserve_url
    } else {
      $('.failed-cancel-reserve-dialog').show();
    }
  }, () => {
    $('.failed-cancel-reserve-dialog').show();
  })
}

/**
 * アニメーションつきでステキ数のセットを行う
 */
function setStekiWithAnimate (loveButton, numSteki) {
  var loveButtonParent = loveButton.parent()
  loveButton.data('num-suteki', numSteki)
  loveButton.data('num-total-suteki', numSteki)
  loveButton.addClass('sutekiscalenormal').delay(500).queue(function (next) {
    loveButton.removeClass('sutekiscalenormal')
    next()
  })

  loveButtonParent.find('.suteki-counter').animate({
    'opacity' : '1',
  }, 0)

  loveButtonParent.find('.suteki-counter').fadeIn('100')
  loveButtonParent.find('.suteki-counter').fadeOut('5000')
  loveButtonParent.find('.mask-color').css('height', STEKI_METER_HEIGHTS[numSteki])

  if (numSteki >= 10) {
    loveButtonParent.find('.flush-heart').addClass('target')
    loveButtonParent.addClass('liked', 400, 'swing', () => {
      loveButtonParent.find('.flush-heart').removeClass('target')
    })
    loveButtonParent.find('.mask-color').css('background', 'white')
    loveButtonParent.find('.love-button').addClass('suteki-scale')
    loveButtonParent.find('.suteki-counter-number').css('font-size', '30px')
  }

  loveButtonParent.find('.love-button-count-span').html(function (i, val) {
    return numSteki
  })
  loveButtonParent.find('.suteki-counter-number').html(function (i, val) {
    return numSteki
  })
}


//
// Cropper
//
$(function () {
  // 画像選択時
  var kSuidbase = ''
  var kSuid = ''
  var photoid = ''
  // 新規登録
  $('.private-karte-photos').on('change', '.launch-crop-new-image', function (e) {
    var file = e.target.files[0]
    if (file === undefined || file.type.indexOf('image') < 0) {
      alert('画像を選択してください')
      return false
    }
    kSuidbase = $('.private-area').attr('data-ksuidbase')
    kSuid = $('.private-area').attr('data-ksuid')
    photoid = $(this).attr('data-photoid')
    launchCropper(file)
  })

  // 更新
  $('.private-karte-photo-edit-dialog').on('change', '.launch-crop-edit-image', function (e) {
    $('.private-karte-photo-edit-dialog').hide()
    var file = e.target.files[0]
    if (file === undefined || file.type.indexOf('image') < 0) {
      alert('画像を選択してください')
      return false
    }
    kSuidbase = $('.private-area').attr('data-ksuidbase')
    kSuid = $('.private-area').attr('data-ksuid')
    photoid = $('.launch-crop-edit-image').attr('data-photoid')
    launchCropper(file)
  })

  function launchCropper (file) {
    var reader = new FileReader()
    // トリミング画面をフェードインさせる
    reader.onload = (function (e) {
      $('.overlay').fadeIn()
      $('.crop_modal').append($('<img>').attr({
        src: e.target.result,
        height: '100%',
        class: 'preview',
        id: 'crop_img',
        title: file.name
      }))

      var aspectRatio = 1
      initIconCrop(aspectRatio)
    })
    reader.readAsDataURL(file)
  }

  // cropper（トリミング部）のコーディング（詳しくはGitHub参照）
  var cropper
  var croppable = false
  function initIconCrop (aspectRatio) {
    cropper = new Cropper(crop_img, {
      dragMode: 'move',
      aspectRatio: aspectRatio,
      viewMode: 1,
      restore: false,
      guides: false,
      center: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      ready: function () {
        croppable = true
      }
    })
  }

  // croppedCanvas（トリミング後の画像をプレビューとして表示するための部分）のコーディング
  var croppedCanvas
  function iconCropping () {
    if (!croppable) {
      alert('トリミングする画像が設定されていません。')
      return false
    }
    croppedCanvas = cropper.getCroppedCanvas({
      width: 800,
      height: 800
    })
    var croppedImage = document.createElement('img')
    croppedImage.src = croppedCanvas.toDataURL()

    // 画像アップロード
    Ajax.setPrivateKartePhoto(kSuid, kSuidbase, photoid, croppedImage.src, (result) => {
      // nothing to do
    })
  }

  // トリミング決定時
  $('.select_icon_btn').on('click', function () {
    iconCropping()
    $('.overlay').fadeOut()
    $('#crop_img').remove()
    $('.cropper-container').remove()
  })

  // トリミング画面を閉じる時
  $('.close_btn').on('click', function () {
    $('.overlay').fadeOut()
    $('#crop_img').remove()
    $('.cropper-container').remove()
  })
})

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
  }
}

//【速度改善】ページ読み込んで0.3秒後に3枚目以降のカルテを表示
document.addEventListener("DOMContentLoaded", function() {
  setTimeout(function() {
    var archiveListItems = document.querySelectorAll(".common-mykarte-archive-list");

    for (var i = 2; i < archiveListItems.length; i++) {
      archiveListItems[i].style.display = "block";
    }
  }, 300);
});
